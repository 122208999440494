// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/


import '../scss/main.scss';

import 'foundation-sites/dist/js/plugins/foundation.core';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();
});
